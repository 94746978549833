// @ts-nocheck
import React, { useState } from 'react';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';
import { PairedExplanationStruct } from '../types';
import { HIGHLIGHT_COLOR_A, HIGHLIGHT_COLOR_B } from '../constants';

interface PairedExplanationsTabProps {
    explanations: PairedExplanationStruct[],
}

const A_PATTERN = /[Pp]aper A/g;
const B_PATTERN = /[Pp]aper B/g;
const A_LENGTH = 7;
const B_LENGTH = 7;

export const PairedExplanationsTab = ({explanations}: PairedExplanationsTabProps) => {
    const [paperIdx, setPaperIdx] = useState(0);
    const handlePaperChange = (event: SelectChangeEvent) => {
        setPaperIdx(Number(event.target.value));
    }

    const getHighlightedText = (text: string) => {
        const aIndices = [...text.matchAll(A_PATTERN)].map(a => ({start: a.index, end: a.index + A_LENGTH, type: 'a'}));
        const bIndices = [...text.matchAll(B_PATTERN)].map(b => ({start: b.index, end: b.index + B_LENGTH, type: 'b'}));
        const indices = [...aIndices, ...bIndices];
        indices.sort((a, b) => a.start - b.start);
        const patches = []
        if (indices.length > 0) {
            if (indices[0].start > 0) {
                patches.push({
                    start: 0,
                    end: indices[0].start,
                    type: 't'
                })
            }
            for (let i = 0; i < indices.length; i++) {
                patches.push(indices[i]);
                if (indices[i].end < text.length) {
                    if (i < indices.length - 1) {
                        patches.push({
                            start: indices[i].end,
                            end: indices[i + 1].start,
                            type: 't'
                        })
                    } else {
                        patches.push({
                            start: indices[i].end,
                            end: text.length,
                            type: 't'
                        })
                    }
                }
            }
        } else {
            patches.push({
                start: 0,
                end: text.length,
                type: 't'
            });
        }

        return (
            <div>
                {patches.map((patch) => {
                    return (
                        <span style={{
                            background: patch.type === 'a'
                                ? HIGHLIGHT_COLOR_A
                                : (patch.type === 'b'
                                    ? HIGHLIGHT_COLOR_B : 'transparent')

                        }}>
                            {text.slice(patch.start, patch.end)}
                        </span>
                    )
                })}
            </div>
        )
    }

    return (
        <div style={{padding: '0 16px', marginTop: 0}}>
        <FormControl size="small" sx={{ mt: 4 }}>
            <Typography variant="body1">
                <span style={{backgroundColor: HIGHLIGHT_COLOR_B}}>Paper B </span>
            </Typography>
            <Select
                value={paperIdx.toString()}
                onChange={handlePaperChange}
            >
                {explanations.map((exp, idx) => {
                    return (
                        <MenuItem value={`${idx}`}
                        >{exp.paired_work.title}</MenuItem>
                    )
                })}
            </Select>
        </FormControl>
        <div>
            <div style={{marginTop: '16px'}}>
                {getHighlightedText(explanations[paperIdx].explanation)}
            </div>
        </div>
        </div>
    )
}