import React from 'react';
import { ParagraphStruct } from '../types';
import Typography from '@mui/material/Typography';
import { Divider } from '@mui/material';
import { AUTHOR_URL } from '../constants';

interface RelatedWorkTabProps {
    relatedWork: ParagraphStruct[] | undefined,
}

export const RelatedWorkTab = ({relatedWork}: RelatedWorkTabProps) => {
    return (
        <div style={{maxHeight: '250px', overflow: 'scroll'}}>
            {
                relatedWork?.map((paragraph, idx) => {
                    return (
                        <div style={{marginBottom: '8px'}}>
                            {paragraph.text}
                        </div>
                    )
                })
            }
        </div>
    )
}