import React, { useState } from 'react';
import { Typography } from '@mui/material';

import { SingleExplanationStruct } from '../types';

interface SingleExplanationsTabProps {
    explanations: SingleExplanationStruct[],
}

export const SingleExplanationsTab = ({explanations}: SingleExplanationsTabProps) => {
    console.log('explanations', explanations)
    return (
        <div>
            <div style={{padding: '0 16px'}}>
                <Typography variant="h6" sx={{marginBottom: 0, marginTop: '24px'}}>Problem</Typography>
                {explanations[0].Problem}
                <Typography variant="h6" sx={{marginBottom: 0, marginTop: '24px'}}>Method</Typography>
                {explanations[0].Method}
                <Typography variant="h6" sx={{marginBottom: 0, marginTop: '24px'}}>Findings</Typography>
                {explanations[0].Findings}
            </div>
        </div>
    )
}