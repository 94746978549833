import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import useFetch, { CachePolicies } from 'use-http';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
    TextField,
    Select,
    Alert,
    AlertTitle,
    MenuItem,
    FormHelperText,
    Stack,
    InputLabel,
    FormControl,
    Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { MaxWidthText } from '@allenai/varnish2/components';

import { Answer } from '../api/Answer';
import { Query } from '../api/Query';

/**
 * This type defines the different values that will be accepted from the form's inputs.
 * The names of the keys (Eg. 'question', 'choices') must match
 * the HTML input's declared 'name' attribute.
 *
 * Eg:
 * ``` <Form.Item label="Question:" name="question"> ... </Form.Item>
 *
 * In this example, we aligned the
 * form's 'name' attributes with the keys of the 'Query' type that is used to query the API.
 */
type FormValue = Partial<Query>;

export const Home = () => {
    /**
     * These are react hooks. You can read more about hooks here:
     * @see https://legacy.reactjs.org/docs/hooks-intro.html
     */
    const navigate = useNavigate();
    const location = useLocation();

    /**
     * This fetches an answer by hitting /api/solve, which is defined in
     * api.py. The provided query is serialized and sent across the wire as JSON.
     *
     * usefetch is a library for making HTTP requests.
     * You can find more about it here:
     * @see https://github.com/ava/use-http
     *
     * /api/solve takes a `Query` and returns an `Answer`:
     */
    const {
        post: solvePost,
        response: solveResponse,
        loading: solveLoading,
        error: solveError,
    } = useFetch<Answer>('/api/solve', { cachePolicy: CachePolicies.NO_CACHE });

    /**
     * We use Yup for easy form validation.
     * You can read more about Yup here:
     * @see https://github.com/jquense/yup
     */
    const formValidation = Yup.object().shape({
        question: Yup.string().required('Question is required'),
        choices: Yup.array()
            .required('At least one answer is required')
            .min(1, 'At least one answer is required'),
    });

    /**
     * We use Formik for easy form creation.
     * You can read more about Formik here:
     * @see https://formik.org/
     */
    const form = useFormik<FormValue>({
        initialValues: {
            question: undefined,
        },
        validationSchema: formValidation,
        onSubmit: (values: FormValue) => {
            handleSubmit(values);
        },
    });

    /**
     * This is a lifecycle function that's called by React after the component
     * has first been rendered and specifically when the 'location' data changes.
     *
     * You can read more about React component's lifecycle here:
     * @see https://reactjs.org/docs/state-and-lifecycle.html
     */
    useEffect(() => {
        fetchAnswer();
    }, [location]);

    /**
     * Submits a call to the API for a given query as defined in the URL parameters
     */
    const fetchAnswer = async function () {
        const query = Query.fromQueryString(location.search);

        // ensure that we have the expected parameters in the URL
        if (query.isValid()) {
            // remove empty question and answer options from the form so that the user knows they're not valid
            const cleanedQuery = new Query(
                query.question.trim(),
                query.choices.filter((choice) => choice.trim() !== '')
            );
            form.setValues(cleanedQuery);

            // Note: validation is occurring on the backend, so errors will be thrown if there are any invalid inputs
            await solvePost(cleanedQuery);
        }
    };

    /**
     * This handler is invoked when the form is submitted, which occurs when
     * the user clicks the submit button or when the user clicks input while
     * the button and/or a form element is selected.
     *
     * We use this instead of a onClick button on a button as it matches the
     * traditional form experience that end users expect.
     *
     * @see https://reactjs.org/docs/forms.html
     */
    const handleSubmit = (values: FormValue) => {
        // We add the query params to the URL, so that users can link to
        // our demo and share noteworthy cases, edge cases, etc.
        const query = new Query(values.question, values.choices);
        // pushing this new URL will automatically trigger a new query (see the 'useEffect' function above)
        navigate(`/?${query.toQueryString()}`);
    };

    /**
     * The render method defines what's rendered. When writing yours keep in
     * mind that you should try to make it a "pure" function of the component's
     * props and state.  In other words, the rendered output should be expressed
     * as a function of the component properties and state.
     *
     * React executes render whenever a component's properties and/or state is
     * updated. The output is then compared with what's rendered and the
     * required updates are made. This is to ensure that rerenders make as few
     * changes to the document as possible -- which can be an expensive process
     * and lead to slow interfaces.
     */
    return (
        <div>
            <h1>Hi</h1>
            <MaxWidthText as="p">
                Enter a question and answers below to see what answer our application selects.
            </MaxWidthText>
            <FormStack spacing={2}>
                <TextField
                    label="Question"
                    name="question"
                    required
                    fullWidth
                    multiline
                    rows={4}
                    placeholder="Enter a question"
                    value={form.values.question}
                    onChange={form.handleChange}
                    error={!!form.errors.question}
                    helperText={form.errors.question}
                />
                <FormControl required>
                    <InputLabel error={form.errors.choices !== undefined}>Answers</InputLabel>
                    <Select
                        label="Answers"
                        name="choices"
                        required
                        fullWidth
                        multiple
                        value={form.values.choices || []}
                        onChange={form.handleChange}
                        error={!!form.errors.choices}>
                        {['Grapefruit', 'Lemon', 'Lime', 'Orange'].map((option) => (
                            <MenuItem key={option} value={option}>
                                {option}
                            </MenuItem>
                        ))}
                    </Select>
                    {form.errors.choices ? (
                        <FormHelperTextError>{form.errors.choices}</FormHelperTextError>
                    ) : null}
                </FormControl>
                {/* Warning: If you choose to remove this Button's 'loading' attribute, you will be responsible for
                        handling multiple asynchronous requests which could lead to inconsistencies. */}
                <Button
                    variant="contained"
                    loading={solveLoading}
                    disabled={!form.isValid}
                    onClick={form.submitForm}>
                    Submit
                </Button>

                {solveError ? (
                    <Alert severity="error">
                        {solveError.message || 'Sorry, something went wrong.'}
                    </Alert>
                ) : null}
                {!solveError && solveResponse.data ? (
                    <>
                        <Alert severity="success">
                            <AlertTitle>Our system answered:</AlertTitle>
                            {`${solveResponse.data.answer} (${solveResponse.data.score}%)`}
                        </Alert>
                        <Alert severity="info">
                            <AlertTitle>Debug</AlertTitle>
                            <Typography>Input:</Typography>
                            {JSON.stringify(form.values, null, 4)}
                            <Typography>Output:</Typography>
                            {JSON.stringify(solveResponse.data, null, 4)}
                        </Alert>
                    </>
                ) : null}
            </FormStack>
        </div>
    );
};

/**
 * The definition below creates a component that we can use in the render
 * function above that have extended / customized CSS attached to them.
 * Learn more about styled components:
 * @see https://www.styled-components.com/
 *
 *
 * CSS is used to modify the display of HTML elements. If you're not familiar
 * with it here's quick introduction:
 * @see https://developer.mozilla.org/en-US/docs/Web/CSS
 */
const FormStack = styled(Stack)`
    max-width: 600px;
`;

/**
 * Matches style for helperText.
 * Needed because Select does not have helperText.
 */
const FormHelperTextError = styled(FormHelperText).attrs({ error: true })`
    && {
        margin: 3px 14px 0;
    }
`;

/**
 * Makes button not full width
 */
const Button = styled(LoadingButton)`
    width: 130px;
`;
