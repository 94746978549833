import React, { useState} from 'react';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import { RecommendationStruct } from '../types';

import { SingleExplanationsTab } from './SingleExplanationsTab';
import { PairedExplanationsTab } from './PairedExplanationsTab';
import { ReferencesTab } from './ReferencesTab';
import { RelatedWorkTab } from './RelatedWorkTab';

interface ExplanationTabsProps {
    recommendation: RecommendationStruct,
    cond: string | undefined,
    onTabChange?: (prevTab: string, newTab: string) => void,
}

export const ExplanationTabs = ({recommendation, cond, onTabChange}: ExplanationTabsProps) => {
    const [tabValue, setTabValue] = useState(0);
    const explanations = recommendation.explanations;
    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        if (onTabChange) {
            if (tabValue === 0  && explanations.paired.length > 0) {
                onTabChange('paired', newValue == 2 ? 'ref' : 'single');
            } else if (newValue === 0 && explanations.paired.length > 0) {
                onTabChange(tabValue == 2 ? 'ref' : 'single', 'paired');
            }
        }
        setTabValue(newValue);
    };
    return (
        <Box>
            {cond == '1' && (
                <>
                    <Tabs value={tabValue} onChange={handleTabChange} aria-label="basic tabs example" sx={{borderBottom: '1px solid #e0e0e0'}}>
                        <Tab label="Abstract" value={0} />
                        {recommendation.paper.relatedWork && recommendation.paper.relatedWork.length > 0 &&
                        (<Tab label="Related work" value={1} />)}
                        {/* <Tab label="References" value={1} /> */}
                    </Tabs>
                    {tabValue === 0 && (
                        <div style={{maxHeight:'250px', overflow:'scroll'}}>
                            <p>{recommendation.paper.abstract}</p>
                        </div>
                    )}
                    {tabValue === 1 && (
                        <div>
                            <RelatedWorkTab relatedWork={recommendation.paper.relatedWork} />
                            {/* <ReferencesTab references={recommendation.paper.references} /> */}
                        </div>
                    )}
                </>
            )}
            {cond == '2' && (<>
                <Tabs value={tabValue} onChange={handleTabChange} aria-label="basic tabs example" sx={{borderBottom: '1px solid #e0e0e0'}}>
                    {explanations.paired.length > 0 && (<Tab label="Relate to Paper" value={0} />)}
                    {explanations.single.length > 0 && (
                        <Tab label="Problem, method, and findings" value={explanations.paired.length > 0 ? 1 : 0} />)}
                    <Tab label="Abstract" value={3} />
                    {recommendation.paper.relatedWork && recommendation.paper.relatedWork.length > 0 &&
                        (<Tab label="Related work" value={2} />)}
                    {/* <Tab label="References" value={2} /> */}
                </Tabs>
                {tabValue === 0  && explanations.paired.length > 0 && (
                    <PairedExplanationsTab explanations={explanations.paired} />
                )}
                {(tabValue === 1 || (tabValue === 0 && explanations.paired.length == 0)) && (
                    <SingleExplanationsTab explanations={explanations.single} />
                )}
                {tabValue === 3 && (
                        <div>
                            <p>{recommendation.paper.abstract}</p>
                        </div>
                    )}
                {
                    tabValue === 2 && (
                        <div>
                            <RelatedWorkTab relatedWork={recommendation.paper.relatedWork} />

                            {/* <ReferencesTab references={recommendation.paper.references} /> */}
                        </div>
                    )
                }
            </>)}
        </Box>
    )
}