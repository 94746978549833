import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import FolderIcon from '@mui/icons-material/Folder';
import Box from '@mui/material/Box';
import DescriptionIcon from '@mui/icons-material/Description';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { FolderStruct, PaperStruct } from '../types';
import { AUTHOR_URL } from '../constants';

export const FolderPapers = () => {
    const { user_id, cond } = useParams<{user_id: string, cond: string}>();
    const [folder, setFolder] = useState<FolderStruct | null>(null);
    const [papers, setPapers] = useState<PaperStruct[]>([]); // paper ids
    useEffect(() => {
        axios.get(`../../api/get-folder/${user_id}/${cond}`)
            .then((res) => {
                const data = res.data
                setFolder({
                    title: data.title,
                    description: data.description
                });
                setPapers(data.papers);
             })
    }, []);
    return (
        <div>
            <h1><FolderIcon sx={{ fontSize: '3rem' }}/>{folder && folder.title}</h1>
            <p>{folder && folder.description}</p>
            {papers.map((paper: PaperStruct) => {
                return (
                    <Box sx={{
                        background: '#fff',
                        padding: '24px',
                        boxShadow: '0 1px 3px 0 rgba(0,0,0,.3)',
                        marginBottom: '24px',
                    }}>
                        <a href={`https://www.semanticscholar.org/paper/${paper.paperId}`}>
                            <h3 style={{marginTop: 0}}>
                                <span style={{ paddingRight: '4px'}}>
                                <DescriptionIcon />
                                {paper.title}
                                </span>
                            </h3>
                        </a>
                        <Typography variant="body1">
                                {
                                paper.authors.map((author, idx) => (
                                    <span key={`author-${author.authorId}`}>
                                    <a href={`${AUTHOR_URL}/${author.authorId}`} target="_blank" data-heap-ref='author-link'>
                                        {author.name}
                                    </a>
                                    {idx < paper.authors.length - 1 && ', '}</span>
                                ))
                            }
                            {paper.venue && (<span> • {paper.venue}</span>)}
                            {paper.year && (<span> • {paper.year}</span>)}
                        </Typography>
                        <p><strong>TLDR</strong>: {paper.tldr}</p>
                        {paper.notes && paper.notes !== '' && (
                            <div>
                                <div><strong>Notes</strong></div>
                                <div>{paper.notes}</div>
                            </div>
                        )}

                    </Box>
                )
            })}
        </div>
    )
}
