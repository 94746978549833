import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import debounce from 'lodash/debounce';

import FolderIcon from '@mui/icons-material/Folder';
import DescriptionIcon from '@mui/icons-material/Description';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import BookmarkRemoveIcon from '@mui/icons-material/BookmarkRemove';
import Chip from '@mui/material/Chip';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { ExplanationTabs } from '../components/ExplanationsTabs';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import { FolderStruct, RecommendationStruct } from '../types';
import { HIGHLIGHT_COLOR_A, AUTHOR_URL } from '../constants';
import { Typography } from '@mui/material';

export const RecommendedPapers = () => {
    const [recommendations, setRecommendations] = useState<RecommendationStruct[]>([]);
    const [isSaves, setIsSaves] = useState<boolean[]>([]);
    const [saveButtonStates, setSaveButtonStates] = useState<string[]>([]); // 'save', 'remove', 'saved'
    const [notes, setNotes] = useState<string[]>([]);
    const [folder, setFolder] = useState<FolderStruct | null>(null);
    const [paperAVisible, setPaperAVisible] = useState<boolean[]>([]);
    const { user_id, cond } = useParams<{user_id: string, cond: string}>();
    useEffect(() => {
        axios.post(`../../api/recommended-papers/${user_id}/${cond}`).then((res) => {
            const data = res.data;
            setFolder({
                title: data.title,
                description: data.description
            })
            const _recommendations = extract_recommendations(data.recommendations)
            setRecommendations(_recommendations);
            const _isSaves = new Array(_recommendations.length).fill(false)
            const _paperAVisible = new Array(_recommendations.length).fill(false)
            const _saveButtonStates = new Array(_recommendations.length).fill('save')
            const _notes = new Array(_recommendations.length).fill('')
            const recordedPapers = data.recorded_papers;
            recordedPapers.forEach((p: {paper_id: string, is_save: boolean, notes: string }) => {
                const idx = _recommendations.findIndex((rec: RecommendationStruct) => rec.paper.paperId === p.paper_id);
                if (idx >= 0) {
                    _isSaves[idx] = p.is_save;
                    _saveButtonStates[idx] = p.is_save ? 'saved' : 'save';
                    _notes[idx] = p.notes;
                }
            })
            _recommendations.forEach((rec: RecommendationStruct, idx: number) => {
                if (cond == '2' && rec.explanations.paired.length > 0) {
                    _paperAVisible[idx] = true;
                }
            });
            setIsSaves(_isSaves);
            setSaveButtonStates(_saveButtonStates);
            setPaperAVisible(_paperAVisible);
            setNotes(_notes);
        })
    }, []);

    const saveUnsavePaper = (paperId: string, idx: number) => {
        const isSave = isSaves[idx];
        axios.post(`../../api/save-paper/${user_id}/${cond}`,
            {isSave: !isSave, paperId }
        ).then((res) => {
                const newIsSaves = [...isSaves];
                newIsSaves[idx] = !isSave;
                setIsSaves(newIsSaves);

                const newSaveButtonStates = [...saveButtonStates];
                newSaveButtonStates[idx] = isSave ? 'save' : 'saved';
                setSaveButtonStates(newSaveButtonStates);
        });
    }

    // @ts-ignore
    const extract_explanations = (explanations: any) => {
        const single = explanations.group3
            ? [{
                ...explanations.group3.explanation[0],
                'type': 'group3',
                'id': explanations.group3.explanation_id
            }] : [];

        const group1 = (explanations.group1 && explanations.group1.length > 0)
        ? explanations.group1.map((exp: any) => {
            return {
                ...exp,
                id: exp.explanation_id,
                type: 'group1',
            }
        }) : [];
        const group2 = (explanations.group2 && explanations.group2.length > 0)
        ? explanations.group2.map((exp: any) => {
            return {
                ...exp,
                id: exp.explanation_id,
                type: 'group2',
            }
        }): [];
        const paired_exps = [...group1, ...group2]
        return {
            'single': single,
            'paired': paired_exps
        }
    }
    const extract_recommendations = (recommendations: any) => {
        return recommendations.map((rec: any) => {
            return {
                ...rec,
                explanations: extract_explanations(rec.explanations)
            }
        })
    }

    const getSaveButtonLabel = (idx: number) => {
        let label = '';
        let icon = <BookmarkBorderIcon />;
        switch (saveButtonStates[idx]) {
            case 'save':
                label = 'Save to Folder';
                break;
            case 'saved':
                label = 'In Folder';
                icon = <BookmarkIcon />;
                break;
            case 'remove':
                label = 'Remove from Folder';
                icon = <></>;//<BookmarkRemoveIcon />;
                break;
        }
        return [label, icon ]
    }

    const handleTabChange = (prevTab: string, newTab: string, idx: number ) => {
        if (prevTab == 'paired') {
            const newPaperAVisible = [...paperAVisible];
            newPaperAVisible[idx] = false;
            setPaperAVisible(newPaperAVisible);
        }
        if (newTab == 'paired') {
            const newPaperAVisible = [...paperAVisible];
            newPaperAVisible[idx] = true;
            setPaperAVisible(newPaperAVisible);
        }
    }

    const saveNotes = debounce((idx: number, notes: string) => {
        axios.post(`../../api/save-notes/${user_id}/${cond}`,
            {paperId: recommendations[idx].paper.paperId, notes}
        ).then((res) => {
        });
    }, 300);

    return (
        <div>
            <h1><FolderIcon sx={{ fontSize: '3rem' }}/>{folder && folder.title}</h1>
            <p>{folder && folder.description}</p>
            {
                recommendations.map((rec: RecommendationStruct, idx: number) => {
                    const [saveButtonLabel, saveButtonIcon] = getSaveButtonLabel(idx);

                    return (
                        <Box sx={{
                            background: '#fff',
                            padding: '24px',
                            boxShadow: '0 1px 3px 0 rgba(0,0,0,.3)',
                            marginBottom: '24px',
                        }}>
                            <div style={{marginBottom: '8px'}}>
                                <Grid container spacing={2}>
                                    <Grid item xs={2}>
                                        <Button size="small"
                                            fullWidth
                                            variant={
                                                saveButtonStates[idx] === 'saved'
                                                ? "contained"
                                                : "outlined"
                                            }
                                            sx={{marginRight: '8px'}}
                                            startIcon={saveButtonIcon}
                                            onClick={()=> {
                                                saveUnsavePaper(rec.paper.paperId, idx);
                                            }}
                                            onMouseOver={() => {
                                                if (saveButtonStates[idx] === 'saved') {
                                                    const newSaveButtonStates = [...saveButtonStates];
                                                    newSaveButtonStates[idx] = 'remove';
                                                    setSaveButtonStates(newSaveButtonStates);
                                                }

                                            }}
                                            onMouseOut={() => {
                                                if (saveButtonStates[idx] === 'remove') {
                                                    const newSaveButtonStates = [...saveButtonStates];
                                                    newSaveButtonStates[idx] = 'saved';
                                                    setSaveButtonStates(newSaveButtonStates);
                                                }
                                            }}
                                        >
                                        {saveButtonLabel}
                                        </Button>
                                    </Grid>
                                    <Grid item xs={10}>
                                        {/* <div style={{marginTop: '16px'}}><strong>Notes</strong></div> */}
                                            <TextField
                                                value={notes[idx]}
                                                multiline
                                                maxRows={1}
                                                size="small"
                                                label="Notes"
                                                fullWidth
                                                onChange={(e) => {
                                                    const text = e.target.value;
                                                    const newNotes = [...notes];
                                                    newNotes[idx] = text;
                                                    setNotes(newNotes);
                                                    saveNotes(idx, text);
                                                }}
                                            />
                                    </Grid>
                                </Grid>
                            </div>
                            <Typography variant="body1" sx={{marginTop: '18px'}}>
                                <span style={{
                                    backgroundColor: (cond == '2' && paperAVisible[idx])
                                        ? HIGHLIGHT_COLOR_A : 'transparent'}}>

                                    {
                                        cond == '2' && paperAVisible[idx] ?
                                        'Paper A': 'Recommended paper'
                                    }
                                </span>
                            </Typography>
                            <a href={`https://www.semanticscholar.org/reader/${rec.paper.paperId}`} target="_blank">
                                <h3 style={{marginTop: 0, marginBottom: '4px'}}>
                                    <span style={{ paddingRight: '4px'}}>
                                    <DescriptionIcon />
                                    {rec.paper.title}
                                    </span>
                                </h3>
                            </a>
                            <Typography variant="body1">
                                {
                                    rec.paper.authors.map((author, idx) => (
                                        <span key={`author-${author.authorId}`}>
                                        <a href={`${AUTHOR_URL}/${author.authorId}`} target="_blank" data-heap-ref='author-link'>
                                            {author.name}
                                        </a>
                                        {idx < rec.paper.authors.length - 1 && ', '}</span>
                                    ))
                                }
                                {rec.paper.venue && (<span> • {rec.paper.venue}</span>)}
                                {rec.paper.year && (<span> • {rec.paper.year}</span>)}
                            </Typography>

                            <p><strong>TLDR</strong>: {rec.paper.tldr}</p>

                            <ExplanationTabs recommendation={rec} cond={cond} onTabChange={(prevTab, newTab) => {
                                handleTabChange(prevTab, newTab, idx);
                            }} />

                        </Box>
                    )
                })
            }
        </div>
    )
}
